(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/tipsinfo/assets/javascripts/tipsinfo-row.js') >= 0) return;  svs.modules.push('/components/tipsen/tipsinfo/assets/javascripts/tipsinfo-row.js');
"use strict";

const {
  useContext
} = React;
const {
  getOutcomeLabelsForEventType
} = svs.components.sport.tipsenShared;
const {
  useCurrentDraw
} = svs.components.tipsen.drawProvider.hooks;
const {
  EngineProviderContext
} = svs.components.tipsen.engine;
const {
  useBranding
} = svs.components.tipsen.hooks;
const {
  EventTypeId
} = svs.components.sportinfo.common.constants;
const {
  isTextOrNumber
} = svs.components.lbUi.trend.utils;
const TipsinfoRowStats = _ref => {
  let {
    children,
    sign = '',
    noValueSign = '-',
    className = ''
  } = _ref;
  const classes = ['tipsinfo-row-stats-option'];
  if (className.length > 0) {
    classes.push(className);
  }
  return React.createElement("div", {
    className: "tipsinfo-row-stats tipsinfo-statistics-grid"
  }, React.Children.map(children, value => React.createElement("div", {
    className: classes.join(' ')
  }, isTextOrNumber(value) ? "".concat(value).concat(sign) : noValueSign)));
};
const TipsinfoRowText = _ref2 => {
  let {
    children,
    className
  } = _ref2;
  const classNames = ['tipsinfo-row-text'];
  className && classNames.push(className);
  return React.createElement("div", {
    className: classNames.join(' ')
  }, children || '-');
};
const TipsinfoRowAnalysisForecast = _ref3 => {
  let {
    children,
    className,
    title,
    dataTestid = ''
  } = _ref3;
  const {
    currentEngine
  } = useContext(EngineProviderContext);
  const draw = useCurrentDraw();
  const eventTypeId = currentEngine.outcomes.eventTypeId;
  const sportType = draw === null || draw === void 0 ? void 0 : draw.sportType;
  const outcomeLabels = getOutcomeLabelsForEventType(eventTypeId, sportType);
  const [productBranding] = useBranding();
  const isGoalCount = eventTypeId === EventTypeId.GOAL_COUNT;
  const classNames = isGoalCount ? ['tipsinfo-row-fulltraff'] : ['tipsinfo-row'];
  className && classNames.push(className);
  const isSelected = (outcomes, sign) => outcomes.some(outcome => outcome.outcomeNumber === sign.toString());
  return React.createElement("div", {
    className: classNames.join(' '),
    "data-testid": dataTestid
  }, React.createElement("div", {
    className: "tipsinfo-row-title"
  }, title), isGoalCount ? React.createElement(TipsinfoRowText, {
    className: "tipsinfo-row-matchstart margin-left-half"
  }, children) : React.createElement("div", {
    className: "tipsinfo-prognos-grid"
  }, outcomeLabels.map(outcomeLabel => React.createElement("div", {
    className: ['btn-bet', 'btn-bet-200', "btn-bet-".concat(productBranding), isSelected(children, outcomeLabel.sign) ? ['btn-bet-selected'] : []].join(' '),
    key: outcomeLabel.text
  }, React.createElement("span", {
    className: "btn-bet-label"
  }, outcomeLabel.text)))));
};
const TipsinfoRow = _ref4 => {
  let {
    className,
    children,
    dataTestid = '',
    title,
    isGoalCount
  } = _ref4;
  const classNames = isGoalCount ? ['tipsinfo-row-fulltraff'] : ['tipsinfo-row'];
  className && classNames.push(className);
  return React.createElement("div", {
    className: classNames.join(' '),
    "data-testid": dataTestid
  }, React.createElement("div", {
    className: "tipsinfo-row-title"
  }, title), children);
};
setGlobal('svs.components.tipsen.tipsinfo', {
  TipsinfoRow,
  TipsinfoRowAnalysisForecast,
  TipsinfoRowText,
  TipsinfoRowStats
});

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/tipsinfo/assets/javascripts/utils.js') >= 0) return;  svs.modules.push('/components/tipsen/tipsinfo/assets/javascripts/utils.js');
"use strict";

const {
  selectEventStatisticCurrentValueByType,
  selectEventStatisticCurrentValueByDrawAndType
} = svs.components.sportinfo.matchInfoRedux.selectors;
const {
  EventTypeField
} = svs.components.sportinfo.common.constants;
const {
  createEventStatisticsKey
} = svs.components.sportinfo.common.helpers;
const getOddsCaption = _ref => {
  let {
    isResultOrMybets,
    isStartOddsEnabled
  } = _ref;
  if (isResultOrMybets) {
    return 'Odds';
  }
  return isStartOddsEnabled ? 'Aktuellt odds' : 'Odds';
};
const formatDecimalOdds = odds => odds ? odds.replace('.', ',') : '-';
const formatMutuals = mutualScore => mutualScore ? Math.round(mutualScore) : null;
const formatPercentage = (percentage, hasDistributions) => {
  let a = null;
  if (percentage) {
    a = Math.round(percentage);
  } else if (hasDistributions) {
    a = 0;
  }
  return a;
};
const createSelectFormattedOdds = (matchId, eventTypeId, participantIds, periodNumber) => index => state => {
  const odds = selectEventStatisticCurrentValueByType(state, createEventStatisticsKey({
    matchId,
    eventTypeId,
    participantIds,
    periodNumber
  }), EventTypeField.Odds);
  return formatDecimalOdds(odds[index]);
};
const createSelectFormattedDistribution = _ref2 => {
  let {
    matchId,
    eventTypeId,
    participantIds,
    productId,
    drawNumber
  } = _ref2;
  return index => state => {
    const eventStatisticId = createEventStatisticsKey({
      matchId,
      eventTypeId,
      participantIds
    });
    const eventTypeField = EventTypeField.Distributions;
    const selectDistribution = selectEventStatisticCurrentValueByDrawAndType(productId, drawNumber, eventStatisticId, eventTypeField);
    const distributions = selectDistribution(state);
    return distributions[index];
  };
};
const hasDistributionValues = function () {
  let productDistributionValues = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Object.values(productDistributionValues).every(value => value !== null && value !== undefined);
};
setGlobal('svs.components.tipsen.tipsinfo.utils', {
  createSelectFormattedOdds,
  createSelectFormattedDistribution,
  formatDecimalOdds,
  formatMutuals,
  formatPercentage,
  getOddsCaption,
  hasDistributionValues
});

 })(window);
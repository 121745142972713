(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/tipsinfo/assets/javascripts/analysis-forecast.js') >= 0) return;  svs.modules.push('/components/tipsen/tipsinfo/assets/javascripts/analysis-forecast.js');
"use strict";


const {
  useSelector
} = ReactRedux;
const {
  selectMatchAnalysIdsForSpecificDrawAndMatchAndEventNumber
} = svs.components.sportinfo.gameAnalysesRedux.selectors;
const {
  TipsinfoRowAnalysisForecast,
  TipsinfoRow
} = svs.components.tipsen.tipsinfo;
const {
  useAnalysisOutcomes
} = svs.components.sportinfo.gameAnalysesRedux.hooks;
const {
  selectMatchAnalysis,
  selectAuthorProfile
} = svs.components.sportinfo.gameAnalysesRedux.selectors;
const {
  selectEngineDefinition
} = svs.components.tipsen.engine.selectors;
const {
  EventTypeId
} = svs.components.sportinfo.common.constants;
const getRowTitle = (isGoalCount, authorShortName) => {
  const lastChar = authorShortName.slice(-1).toLowerCase();
  const endsWithSorX = lastChar === 's' || lastChar === 'x';
  let rowTitle = '';
  if (isGoalCount) {
    rowTitle = "Spelanalys fr\xE5n ".concat(authorShortName);
  } else {
    rowTitle = endsWithSorX ? "".concat(authorShortName, " speltips") : "".concat(authorShortName, "s speltips");
  }
  return rowTitle;
};
const AnalysisForecastRow = _ref => {
  let {
    analysisId,
    dataTestId = '',
    isGoalCount
  } = _ref;
  const matchAnalysis = useSelector(state => selectMatchAnalysis(state, analysisId));
  const authorProfile = useSelector(state => selectAuthorProfile(state, matchAnalysis === null || matchAnalysis === void 0 ? void 0 : matchAnalysis.authorId));
  const outcomes = useAnalysisOutcomes(matchAnalysis)[0];
  const outComesGoalCountDisplay = "".concat(outcomes.map(outcome => outcome.description).join(', '), " m\xE5l");
  const rowTitle = getRowTitle(isGoalCount, authorProfile.authorShortName);
  return React.createElement(TipsinfoRowAnalysisForecast, {
    className: "prognos",
    dataTestid: dataTestId,
    isGoalCount: isGoalCount,
    title: rowTitle
  }, isGoalCount ? outComesGoalCountDisplay : outcomes);
};
const AnalysisForecast = _ref2 => {
  let {
    matchId,
    dataTestid,
    productId,
    drawId,
    eventNumber
  } = _ref2;
  const matchAnalysesIds = useSelector(state => selectMatchAnalysIdsForSpecificDrawAndMatchAndEventNumber(state, productId, drawId, matchId, eventNumber));
  const currentEngine = useSelector(selectEngineDefinition);
  const isGoalCount = currentEngine.outcomes.eventTypeId === EventTypeId.GOAL_COUNT;

  if (!matchAnalysesIds || matchAnalysesIds.length === 0) {
    return React.createElement(TipsinfoRow, {
      className: "prognos-no-data",
      dataTestid: dataTestid,
      isGoalCount: isGoalCount,
      title: "F\xF6r tillf\xE4llet har vi inga speltips"
    });
  }
  return React.createElement(React.Fragment, null, matchAnalysesIds.map(analysisId => React.createElement(AnalysisForecastRow, {
    analysisId: analysisId,
    dataTestid: dataTestid,
    isGoalCount: isGoalCount,
    key: analysisId
  })));
};
setGlobal('svs.components.tipsen.tipsinfo.AnalysisForecast', AnalysisForecast);

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/tipsinfo/assets/javascripts/use-filtered-marks-info-choices.js') >= 0) return;  svs.modules.push('/components/tipsen/tipsinfo/assets/javascripts/use-filtered-marks-info-choices.js');
"use strict";

const {
  useMemo,
  useContext
} = React;
const {
  selectTipsinfoSupport
} = svs.components.tipsen.engine.selectors.engineSelectors;
const {
  useSelector
} = ReactRedux;
const {
  TipsinfoTypes
} = svs.components.tipsen.engine.constants;
const {
  selectOutcomeInfoChoise
} = svs.components.tipsen.selectors;
const {
  RenderContext
} = svs.component.tipsen.renderContext;
const useFilteredOutcomeInfoChoises = () => {
  const {
    pageType
  } = useContext(RenderContext);
  const outcomeInfoChoice = useSelector(state => selectOutcomeInfoChoise(state, pageType));
  const tipsinfoSupport = useSelector(state => selectTipsinfoSupport(state, pageType));
  return useMemo(() => {
    const outcomeInfoChoises = [];
    const outcomeInfoChoisesOptions = {
      [TipsinfoTypes.OutcomeLabel]: '1X2',
      [TipsinfoTypes.SvenskaFolket]: 'Svenska folket (%)'
    };
    const outcomeInfoChoisesKeys = Object.keys(outcomeInfoChoisesOptions);
    for (const key of outcomeInfoChoisesKeys) {
      if (tipsinfoSupport[key]) {
        var _outcomeInfoChoisesOp;
        outcomeInfoChoises.push({
          isSelected: outcomeInfoChoice.display === key,
          key,
          title: (_outcomeInfoChoisesOp = outcomeInfoChoisesOptions[key]) !== null && _outcomeInfoChoisesOp !== void 0 ? _outcomeInfoChoisesOp : ''
        });
      }
    }
    return outcomeInfoChoises;
  }, [outcomeInfoChoice, tipsinfoSupport]);
};
setGlobal('svs.components.tipsen.tipsinfo.useFilteredOutcomeInfoChoises', useFilteredOutcomeInfoChoises);

 })(window);
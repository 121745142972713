(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/tipsinfo/assets/javascripts/use-filtered-tipsinfo-options.js') >= 0) return;  svs.modules.push('/components/tipsen/tipsinfo/assets/javascripts/use-filtered-tipsinfo-options.js');
"use strict";

const {
  useMemo,
  useContext
} = React;
const {
  selectTipsinfo
} = svs.components.tipsen.selectors;
const {
  selectTipsinfoSupport
} = svs.components.tipsen.engine.selectors.engineSelectors;
const {
  useSelector
} = ReactRedux;
const {
  TipsinfoTypes
} = svs.components.tipsen.engine.constants;
const {
  RenderContext
} = svs.component.tipsen.renderContext;
const useFilteredTipsinfoOptions = () => {
  const {
    pageType
  } = useContext(RenderContext);
  const tipsinfoState = useSelector(state => selectTipsinfo(state, pageType));
  const tipsinfoSupport = useSelector(state => selectTipsinfoSupport(state, pageType));
  return useMemo(() => {
    const tipsinfoOptions = [];
    const tipsinfoKeyToTitleMapping = {
      [TipsinfoTypes.Odds]: 'Odds',
      [TipsinfoTypes.StartOdds]: 'Startodds',
      [TipsinfoTypes.SvenskaFolket]: 'Svenska folket',
      [TipsinfoTypes.TioTidningar]: 'Tio tidningar',
      [TipsinfoTypes.Matchstart]: 'Matchstart',
      [TipsinfoTypes.Favourites]: 'Favoritskap utifrån odds',
      [TipsinfoTypes.Mutuals]: 'Inbördes möten',
      [TipsinfoTypes.OverUnder]: 'Över/Under 2,5 mål'
    };
    const tipsinfoKeyToIconMapping = {
      [TipsinfoTypes.Odds]: 'odds',
      [TipsinfoTypes.StartOdds]: 'odds',
      [TipsinfoTypes.SvenskaFolket]: 'players',
      [TipsinfoTypes.TioTidningar]: 'infinity',
      [TipsinfoTypes.Matchstart]: 'clock',
      [TipsinfoTypes.Mutuals]: 'odds'
    };
    const keys = Object.keys(tipsinfoState);
    for (const key of keys) {
      if (tipsinfoSupport[key]) {
        var _tipsinfoKeyToIconMap, _tipsinfoKeyToTitleMa;
        tipsinfoOptions.push({
          isSelected: tipsinfoState[key].state,
          icon: (_tipsinfoKeyToIconMap = tipsinfoKeyToIconMapping[key]) !== null && _tipsinfoKeyToIconMap !== void 0 ? _tipsinfoKeyToIconMap : 'odds',
          key,
          title: (_tipsinfoKeyToTitleMa = tipsinfoKeyToTitleMapping[key]) !== null && _tipsinfoKeyToTitleMa !== void 0 ? _tipsinfoKeyToTitleMa : ''
        });
      }
    }
    const indexOfMutuals = tipsinfoOptions.findIndex(tipsinfo => tipsinfo.key === TipsinfoTypes.Mutuals);
    if (indexOfMutuals >= 0) {
      tipsinfoOptions.splice(indexOfMutuals);
    }
    return tipsinfoOptions;
  }, [tipsinfoState, tipsinfoSupport]);
};
setGlobal('svs.components.tipsen.tipsinfo.useFilteredTipsinfoOptions', useFilteredTipsinfoOptions);

 })(window);